import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import {FormattedMessage} from 'react-intl';
import {Link} from "react-router-dom";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero = ({
                  className,
                  topOuterDivider,
                  bottomOuterDivider,
                  topDivider,
                  bottomDivider,
                  hasBgColor,
                  invertColor,
                  ...props
              }) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            <FormattedMessage
                                id="app.hero.in"
                                defaultMessage="En "
                            />
                            <span className="text-color-primary">ZecTelecom</span>
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                                <FormattedMessage
                                    id="app.hero.home"
                                    defaultMessage="Le ofrecemos las mejores soluciones del mercado para que encajen en sus propuestas y demandas tecnológicas"
                                />
                            </p>
                            <div className="reveal-from-bottom mt-3" data-reveal-delay="600">
                                <ButtonGroup>
                                    <Link style={{margin: 10}} to="/contact">
                                        <Button tag="a" href="#" color="primary" wideMobile>
                                            <FormattedMessage
                                                id="app.hero.solutions"
                                                defaultMessage="Nuestras soluciones"
                                            />
                                        </Button>
                                    </Link>
                                    <Link style={{margin: 10}} to="/contact">
                                        <Button tag="a" href="#" color="primary" wideMobile>
                                            <FormattedMessage
                                                id="app.hero.contact"
                                                defaultMessage="Contáctanos"
                                            />
                                        </Button>
                                    </Link>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px"
                         data-reveal-delay="800">
                        <Image
                            src={require('./../../assets/images/Home_welcome.png')}
                            alt="Hero"
                            width={896}
                            height={504}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;