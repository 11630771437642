import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";

import App from './App';
import * as serviceWorker from './serviceWorker';

//import './App.css';
import './assets/scss/style.scss';

//Languages
import {IntlProvider} from 'react-intl';
import Spanish from './lang/es.json';
import English from './lang/en.json';

const history = createBrowserHistory();

const locale = navigator.language;
let lang;
if (locale.includes("en")) {
    lang = English;
} else {
    lang = Spanish;
}

ReactDOM.render(
    <Router history={history}>
        <IntlProvider locale={locale} messages={lang}>
            <App language={lang}/>
        </IntlProvider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
