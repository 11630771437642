import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import '../../assets/scss/style_contact.scss';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}


const Contact_form = ({
                          className,
                          topOuterDivider,
                          bottomOuterDivider,
                          topDivider,
                          bottomDivider,
                          hasBgColor,
                          invertColor,
                          split,
                          ...props
                      }) => {

    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-11">
                        <div className="wrapper">
                            <div className="row no-gutters justify-content-between">
                                <div className="col-lg-6 d-flex align-items-stretch">
                                    <div className="info-wrap w-100 mt-3">
                                        <h3 className="mb-4">
                                            <FormattedMessage
                                                id="app.hero.contact"
                                                defaultMessage="Contáctanos"
                                            />
                                        </h3>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-map-marker"/>
                                            </div>
                                            <div className="text pl-4">
                                                <p>
                                                    <span>
                                                        <FormattedMessage
                                                            id="app.hero.contactUs_address"
                                                            defaultMessage="Dirección:"
                                                        />
                                                    </span> Calle Gaviota 10, 35500 Arrecife, Lanzarote,
                                                    Las Palmas de Gran Canaria, España
                                                </p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-phone"/>
                                            </div>
                                            <div className="text pl-4">
                                                <p>
                                                    <span>
                                                        <FormattedMessage
                                                            id="app.hero.contactUs_phone"
                                                            defaultMessage="Teléfono:"
                                                        />
                                                    </span>
                                                    <a href="tel://1234567920">+34 928 80 40 20</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane"/>
                                            </div>
                                            <div className="text pl-4">
                                                <p><span>Email:</span> <a
                                                    href="mailto:info@yoursite.com">info@zectelecom.com</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-globe"/>
                                            </div>
                                            <div className="text pl-4">
                                                <p><span>Website</span> <a
                                                    href="https://www.zectelecom.com">zectelecom.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact-wrap w-100 p-md-5 p-4">
                                        <h3 className="mb-4">
                                            <FormattedMessage
                                                id="app.hero.contactUs_form_title"
                                                defaultMessage="Mantente en contacto"
                                            />
                                        </h3>
                                        <form method="POST" id="contactForm" name="contactForm" noValidate="novalidate">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="name"
                                                               id="name" placeholder="Nombre"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="email" className="form-control" name="email"
                                                               id="email" placeholder="Email"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="subject"
                                                               id="subject" placeholder="Tema"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="message" className="form-control" id="message"
                                                                  cols="30" rows="5" placeholder="Mensaje"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Contact_form.propTypes = propTypes;
Contact_form.defaultProps = defaultProps;

export default Contact_form;