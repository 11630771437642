import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../../utils/SectionProps';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const ContentCookie = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'hero section center-content-privacy p-0',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <p className={"about-title"} dir="ltr">
                            <b>
                                <FormattedMessage
                                    id="app.hero.cookies_title1"
                                    defaultMessage="Última actualización"
                                />
                            </b>: 02-05-2022
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title1"
                                defaultMessage="Esta Política de Cookies explica qué son las Cookies y cómo las usamos.
                            Debe leer esta política para que pueda comprender qué tipo de cookies
                            usamos, o la información que recopilamos usando cookies y cómo se usa esa
                            información."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title2"
                                defaultMessage="Las cookies no suelen contener ninguna información que identifique
                            personalmente a un usuario, pero la información personal que almacenamos
                            sobre usted puede estar vinculada a la información almacenada y obtenida de
                            las cookies. Para obtener más información sobre cómo usamos, almacenamos y
                            mantenemos seguros sus datos personales, consulte nuestra Política de
                            privacidad."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title3"
                                defaultMessage="No almacenamos información personal confidencial, como direcciones de
                            correo, contraseñas de cuentas, etc. en las cookies que utilizamos."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title4"
                                defaultMessage="Interpretación y definiciones"
                            />
                        </h1>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title5"
                                defaultMessage="Interpretación"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title6"
                                defaultMessage="Las palabras cuya letra inicial está en mayúscula tienen significados
                            definidos en las siguientes condiciones. Las siguientes definiciones
                            tendrán el mismo significado independientemente de que aparezcan en
                            singular o en plural."
                            />
                        </p>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title7"
                                defaultMessage="Definiciones"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title8"
                                defaultMessage="A los efectos de esta Política de Cookies:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title9"
                                        defaultMessage='Empresa (denominada "la Empresa", "Nosotros", "Nos" o "Nuestro" en
                                    esta Política de Cookies) se refiere a ZecTelecom, Avenida de la onu,
                                    23, 28936, Madrid.'
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title10"
                                        defaultMessage="Sitio web se refiere a ZecTelecom, accesible desde"
                                    />
                                    <a href="https://www.zectelecom.com/">https://www.zectelecom.com</a>
                                </p>
                            </li>
                        </ul>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title11"
                                defaultMessage="¿Qué son las cookies?"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title12"
                                defaultMessage="Las cookies y otras tecnologías similares tales como local shared objects, flash cookies o
                                píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus
                                visitantes, así como para ofrecer un correcto funcionamiento del sitio."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title13"
                                defaultMessage="Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos
                            concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le interesan, etc."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title14"
                                defaultMessage="El uso de las Cookies"
                            />
                        </h1>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title15"
                                defaultMessage="Tipo de cookies que usamos"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title16"
                                defaultMessage='Las cookies pueden ser cookies "persistentes" o de "sesión". Las cookies
                                persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las cookies de sesión se eliminan tan
                                pronto como cierra su navegador web.'
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title17"
                                defaultMessage="Utilizamos cookies de sesión y persistentes para los fines que se
                            establecen a continuación:"
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title18"
                                defaultMessage="Cookies necesarias / esenciales"
                            />
                        </h3>
                        <p dir="ltr">
                            <b><FormattedMessage
                                id="app.hero.cookies_title.type"
                                defaultMessage="Tipo:"
                            /></b>
                            <FormattedMessage
                                id="app.hero.cookies_title19"
                                defaultMessage="Cookies de sesión"
                            />
                        </p>
                        <p dir="ltr">
                            <b><FormattedMessage
                                id="app.hero.cookies_title.managed"
                                defaultMessage="Administrado por:"
                            /></b>
                            <FormattedMessage
                                id="app.hero.cookies_title20"
                                defaultMessage="Nosotros"
                            />
                        </p>
                        <p dir="ltr">
                            <b><FormattedMessage
                                id="app.hero.cookies_title.purpose"
                                defaultMessage="Propósito:"
                            /></b>
                            <FormattedMessage
                                id="app.hero.cookies_title21"
                                defaultMessage="Estas cookies son esenciales para brindarle los servicios
                            disponibles a través del sitio web y para permitirle utilizar algunas de
                            sus funciones. Ayudan a autenticar a los usuarios y a prevenir el uso
                            fraudulento de cuentas de usuario. Sin estas cookies, los servicios que ha
                            solicitado no se pueden proporcionar, y solo usamos estas cookies para
                            brindarle esos servicios."
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title22"
                                defaultMessage="Cookies de funcionalidad"
                            />
                        </h3>
                        <p dir="ltr">
                            <b><FormattedMessage
                                id="app.hero.cookies_title.type"
                                defaultMessage="Tipo:"
                            /></b>
                            <FormattedMessage
                                id="app.hero.cookies_title23"
                                defaultMessage="Cookies persistentes"
                            />
                        </p>
                        <p dir="ltr">
                            <b><FormattedMessage
                                id="app.hero.cookies_title.managed"
                                defaultMessage="Administrado por:"
                            /></b>
                            <FormattedMessage
                                id="app.hero.cookies_title20"
                                defaultMessage="Nosotros"
                            />
                        </p>
                        <p dir="ltr">
                            <b><FormattedMessage
                                id="app.hero.cookies_title.purpose"
                                defaultMessage="Propósito:"
                            /></b>
                            <FormattedMessage
                                id="app.hero.cookies_title24"
                                defaultMessage="estas cookies nos permiten recordar las elecciones que realiza
                            cuando utiliza el sitio web, como recordar sus datos de inicio de sesión o
                            su preferencia de idioma. El propósito de estas cookies es brindarle una
                            experiencia más personal y evitar que tenga que volver a ingresar sus
                            preferencias cada vez que utiliza el sitio web."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title25"
                                defaultMessage="Tratamiento de datos personales"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title26"
                                defaultMessage="ZecTelecom es el Responsable del tratamiento de los datos personales del Interesado y le
                            informa que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de
                            27 de abril de 2016 (GDPR) y , por lo que se le facilita la siguiente información del tratamiento:"
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title27"
                                defaultMessage="Fines del tratamiento"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title28"
                                defaultMessage="Según se especifica en el apartado de cookies que se utilizan en este sitio web."
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title29"
                                defaultMessage="Legitimación del tratamiento"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title30"
                                defaultMessage="Por interés legítimo del responsable: cookies técnicas y por consentimiento del
                                interesado: cookies analíticas."
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title31"
                                defaultMessage="Criterios de conservación de los datos"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title32"
                                defaultMessage="Según se especifica en el apartado de cookies utilizadas en la web."
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title33"
                                defaultMessage="Comunicación de los datos"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title34"
                                defaultMessage="No se comunicarán los datos a terceros, excepto en cookies propiedad de terceros o por obligación legal."
                            />
                        </p>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title35"
                                defaultMessage="Derechos que asisten al Interesado"
                            />
                        </h3>
                        <ul style={{textAlign: 'left'}}>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title36"
                                        defaultMessage="Derecho a retirar el consentimiento en cualquier momento."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title37"
                                        defaultMessage="Derecho de acceso, rectificación, portabilidad y supresión de sus datos y a la limitación u oposición a su tratamiento."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title38"
                                        defaultMessage="Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si
                                    considera que el tratamiento no se ajusta a la normativa vigente"
                                    />
                                </p>
                            </li>
                        </ul>
                        <h3 style={{textAlign: 'justify'}} dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title39"
                                defaultMessage="Datos de contacto para ejercer sus derechos"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title40"
                                defaultMessage="ZecTelecom. Avenida de la onu, 23, 28936 (Madrid). Email: info@zectelecom.com"
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title41"
                                defaultMessage="Sus opciones con respecto a las cookies"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title42"
                                defaultMessage="Si prefiere evitar el uso de cookies en el sitio web, primero debe
                            desactivar el uso de cookies en su navegador y luego eliminar las cookies
                            guardadas en su navegador asociado con este sitio web. Puede utilizar esta
                            opción para evitar el uso de cookies en cualquier momento."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title43"
                                defaultMessage="Si no acepta nuestras cookies, puede experimentar algunos inconvenientes en
                            el uso del sitio web y es posible que algunas funciones no funcionen
                            correctamente."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title44"
                                defaultMessage="Si desea eliminar las cookies o indicarle a su navegador web que elimine o
                            rechace las cookies, visite las páginas de ayuda de su navegador web."
                            />
                        </p>
                        <ul style={{textAlign: 'left', margin: 0, padding: 0}}>
                            <li dir="ltr">
                                <p style={{margin: 0, padding: 0}} dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title45"
                                        defaultMessage="Para el navegador web Chrome, visite esta página de Google:"
                                    />
                                </p>
                                <a href="https://support.google.com/accounts/answer/32050">
                                    https://support.google.com/accounts/answer/32050
                                </a>
                            </li>
                            <li dir="ltr">
                                <p style={{margin: 0, padding: 0}} dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title46"
                                        defaultMessage="Para el navegador web Internet Explorer, visite esta página de Microsoft:"
                                    />
                                </p>
                                <a href="https://support.microsoft.com/kb/278835">
                                    https://support.microsoft.com/kb/278835
                                </a>
                            </li>
                            <li dir="ltr">
                                <p style={{margin: 0, padding: 0}} dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title47"
                                        defaultMessage="Para el navegador web Firefox, visite esta página de Mozilla:"
                                    />
                                </p>
                                <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                                    https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                                </a>
                            </li>
                            <li dir="ltr">
                                <p style={{margin: 0, padding: 0}} dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title48"
                                        defaultMessage="Última actualización Para el navegador web Safari, visite esta página de Apple:"
                                    />
                                </p>
                                <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                                    https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                                </a>
                            </li>
                            <li style={{paddingTop: 15, listStyle: 'none'}} dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.cookies_title49"
                                        defaultMessage="Para cualquier otro navegador web, visite las páginas web oficiales de su
                                    navegador web."
                                    />

                                </p>
                            </li>
                        </ul>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title50"
                                defaultMessage="Contáctenos"
                            />

                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.cookies_title51"
                                defaultMessage="Si tiene alguna duda sobre esta Política de Cookies, puede contactarnos:"
                            />

                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b><FormattedMessage
                                        id="app.hero.cookies_title52"
                                        defaultMessage="Por correo electrónico: "
                                    /></b>info@zectelecom.com
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b><FormattedMessage
                                        id="app.hero.cookies_title53"
                                        defaultMessage="Visitando esta página en nuestro sitio web:"
                                    /></b>
                                    <a
                                        href="https://www.zectelecom.com/">https://www.zectelecom.com</a>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b><FormattedMessage
                                        id="app.hero.cookies_title54"
                                        defaultMessage="Por número de teléfono:"
                                    /></b>
                                    900879345
                                    {/*todo cookies_title num tlf*/}
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b><FormattedMessage
                                        id="app.hero.cookies_title55"
                                        defaultMessage="Por correo: "
                                    /></b>Avenida de la onu, 23, 28936, Madrid
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

ContentCookie.propTypes = propTypes;
ContentCookie.defaultProps = defaultProps;

export default ContentCookie;