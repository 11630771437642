import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Image from '../elements/Image';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero = ({
                  className,
                  topOuterDivider,
                  bottomOuterDivider,
                  topDivider,
                  bottomDivider,
                  hasBgColor,
                  invertColor,
                  ...props
              }) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            <span className="text-color-primary">
                                <FormattedMessage
                                    id="app.hero.contactUs_title"
                                    defaultMessage="Contacta con nosotros"
                                />
                            </span>
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                                <FormattedMessage
                                    id="app.hero.contactUs_description"
                                    defaultMessage="Contacta con nosotros y le responderemos lo antes posible ante cualquier duda o presupuesto."
                                />
                            </p>
                        </div>
                        <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px"
                             data-reveal-delay="800">
                            <Image
                                src={require('./../../assets/images/bubble-gum-workflow.gif')}
                                alt="Hero"
                                width={300}
                                height={32}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;