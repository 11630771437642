import React from 'react';
// import sections
import Hero_contact from "../components/sections/Hero_contact";
import Contact_form from "../components/sections/Contact_form";

const Contact = () => {

    return (
        <>
            <Hero_contact className="illustration-section-01" />
            <Contact_form />
        </>
    );
}

export default Contact;