import React, {useRef, useEffect} from 'react';
import {useLocation, Switch} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import CookieBanner from "react-cookie-banner";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from "./views/About";
import Contact from "./views/Contact";
import CookiesPolicy from "./views/CookiesPolicy";
import PrivacyPolicy from "./views/PrivacyPolicy";

const styles = {
    banner: {
        fontFamily: 'Source Sans Pro',
        height: 57,
        background: 'rgba(52, 64, 81, 0.88) url(https://react-components.buildo.io/cookie.png) 20px 50% no-repeat',
        backgroundSize: '30px 30px',
        backgroundColor: '',
        fontSize: '15px',
        fontWeight: 600,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 40,
    },
    button: {
        border: '1px solid white',
        borderRadius: 4,
        width: 66,
        height: 32,
        lineHeight: '32px',
        background: 'transparent',
        color: 'white',
        fontSize: '14px',
        fontWeight: 600,
        opacity: 1,
        right: 20,
        marginTop: -18
    },
    message: {
        display: 'block',
        padding: '9px 67px',
        lineHeight: 1.3,
        textAlign: 'left',
        marginRight: 244,
        color: 'white'
    },
    link: {
        textDecoration: 'none',
        fontWeight: 'bold',
        color: '#6c6edc',
        marginLeft: '1vw'
    }
}

const App = ({language}) => {
    const childRef = useRef();
    let location = useLocation();

    const message = language === "Spanish" ? "Usamos cookies en nuestra web. Si sigues navegando por la web, entenderemos que las aceptas." : "We use cookies on our website. If you continue to browse the site, we will assume that you accept them."
    const linkMessage = language === "Spanish" ? "Para más información, consulta nuestra política de cookies" : "For more information, see our cookie policy"
    const buttonMessage = language === "Spanish" ? "Cerrar" : "Close"

    window.scrollTo(0, 0);

    useEffect(() => {
        document.body.classList.add('is-loaded')
        childRef.current.init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            <CookieBanner
                styles={styles}
                message={message}
                link={<a href='https://zectelecom.com/cookies-policy'>{linkMessage}</a>}
                buttonMessage={buttonMessage}
                dismissOnScroll={false}
                dismissOnClick={false}
                onAccept={() => {}}
            />
            <ScrollReveal
                ref={childRef}
                children={() => (
                    <Switch>
                        <AppRoute exact path="/" component={Home} layout={LayoutDefault}/>
                        <AppRoute exact path="/about" component={About} layout={LayoutDefault}/>
                        <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault}/>
                        <AppRoute exact path="/cookies-policy" component={CookiesPolicy} layout={LayoutDefault}/>
                        <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault}/>
                    </Switch>
                )}/>
        </>
    );
}

export default App;