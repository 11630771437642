import React from 'react';
// import sections
import Hero_About from "../components/sections/Hero_about";
import FeaturesSplit_About from "../components/sections/FeaturesSplit_about";
import FeaturesTiles_About from "../components/sections/FeaturesTiles_about";
import Cta_About from "../components/sections/Cta_about";

const About = () => {

    return (
        <>
            <Hero_About className="illustration-section-01" />
            <FeaturesSplit_About invertMobile topDivider imageFill className="illustration-section-02"/>
            <FeaturesTiles_About />
            <Cta_About split />
        </>
    );
}

export default About;