import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           pushLeft,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Trabajar con ZecTelecom',
        paragraph: 'Algunas razones para empezar a colaborar con nosotros.',
        section: 'featureTiles'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={tilesClasses}>
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-01.svg')}
                                            alt="Features tile icon 01"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        <FormattedMessage
                                            id="app.hero.feature_one_title"
                                            defaultMessage="Flujo de trabajo robusto"
                                        />
                                    </h4>
                                    <p className="m-0 text-sm">
                                        <FormattedMessage
                                            id="app.hero.feature_one_description"
                                            defaultMessage="La metodología de nuestro equipo de desarrollo sigue una filosofía de flujo de trabajo robusto"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-02.svg')}
                                            alt="Features tile icon 02"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        <FormattedMessage
                                            id="app.hero.feature_two_title"
                                            defaultMessage="Visualizar el proyecto"
                                        />
                                    </h4>
                                    <p className="m-0 text-sm">
                                        <FormattedMessage
                                            id="app.hero.feature_two_description"
                                            defaultMessage="Con las últimas tecnologías del mercado, le propondremos las mejores opciones para que pueda elegir"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-03.svg')}
                                            alt="Features tile icon 03"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        <FormattedMessage
                                            id="app.hero.feature_three_title"
                                            defaultMessage="Conexión mundial"
                                        />
                                    </h4>
                                    <p className="m-0 text-sm">
                                        <FormattedMessage
                                            id="app.hero.feature_three_description"
                                            defaultMessage="Expande tu negocio al mundo entero con nosotros y llega a miles de clientes"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-04.svg')}
                                            alt="Features tile icon 04"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        <FormattedMessage
                                            id="app.hero.feature_four_title"
                                            defaultMessage="Trabajo menos, gana más"
                                        />
                                    </h4>
                                    <p className="m-0 text-sm">
                                        <FormattedMessage
                                            id="app.hero.feature_four_description"
                                            defaultMessage="Confiar en nosotros le ayudará a incrementar su beneficio mensual, delegando algo de trabajo para conseguir beneficios"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-05.svg')}
                                            alt="Features tile icon 05"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        <FormattedMessage
                                            id="app.hero.feature_five_title"
                                            defaultMessage="Sello de garantía"
                                        />
                                    </h4>
                                    <p className="m-0 text-sm">
                                        <FormattedMessage
                                            id="app.hero.feature_five_description"
                                            defaultMessage="Nuestros productos son creados por un equipo de desarrolladores especializados que mejoran cada día"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-06.svg')}
                                            alt="Features tile icon 06"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        <FormattedMessage
                                            id="app.hero.feature_six_title"
                                            defaultMessage="Recomiéndanos"
                                        />
                                    </h4>
                                    <p className="m-0 text-sm">
                                        <FormattedMessage
                                            id="app.hero.feature_six_description"
                                            defaultMessage="Si conoce gente que pueda estar interesada en ponerse en contacto con nosotros, háganoslo saber por favor"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;