import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../../utils/SectionProps';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const ContentPrivacy = ({
                            className,
                            topOuterDivider,
                            bottomOuterDivider,
                            topDivider,
                            bottomDivider,
                            hasBgColor,
                            invertColor,
                            ...props
                        }) => {

    const outerClasses = classNames(
        'hero section center-content-privacy p-0',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <p className={"about-title"} dir="ltr">
                            <b>
                                <FormattedMessage
                                    id="app.hero.privacy_update"
                                    defaultMessage="Última actualización"
                                />
                            </b>
                            <FormattedMessage
                                id="app.hero.privacy_update_date"
                                defaultMessage=": 02 de Mayo de 2022"
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph1"
                                defaultMessage="Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de Su información cuando usa el Servicio y le informa sobre Sus derechos de privacidad y cómo la ley lo protege."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph2"
                                defaultMessage="Usamos sus datos personales para proporcionar y mejorar el servicio. Al utilizar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title1"
                                defaultMessage="Información al usuario"
                            />
                        </h1>
                        <p dir="ltr">
                            <b>ZecTelecom</b>
                            <FormattedMessage
                                id="app.hero.privacy_paragraph3"
                                defaultMessage=", como Responsable del Tratamiento, le informa que, según lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril, (RGPD) y en la L.O. 3/2018, de 5 de diciembre, de protección de datos y garantía de los derechos digitales (LOPDGDD), trataremos su datos tal y como reflejamos en la presente Política de Privacidad."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph4"
                                defaultMessage="En esta Política de Privacidad describimos cómo recogemos sus datos personales y por qué los recogemos, qué hacemos con ellos, con quién los compartimos, cómo los protegemos y sus opciones en cuanto al tratamiento de sus datos personales."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph5"
                                defaultMessage="Esta Política se aplica al tratamiento de sus datos personales recogidos por la empresa para la prestación de sus servicios. Si acepta las medidas de esta Política, acepta que tratemos sus datos personales como se define en esta Política."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title2"
                                defaultMessage="Interpretación y definiciones"
                            />
                        </h1>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title3"
                                defaultMessage="Interpretación"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph6"
                                defaultMessage="Las palabras cuya letra inicial está en mayúscula tienen significados definidos en las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural."
                            />
                        </p>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title4"
                                defaultMessage="Definiciones"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph7"
                                defaultMessage="A los efectos de esta Política de privacidad:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph8"
                                        defaultMessage="Cuenta significa una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph9"
                                        defaultMessage='Compañía (denominada "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a ZecTelecom, Avenida de la onu, 23, 28936, Madrid.'
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph10"
                                        defaultMessage="Las cookies son pequeños archivos que un sitio web coloca en su computadora, dispositivo móvil o cualquier otro dispositivo, que contienen los detalles de su historial de navegación en ese sitio web, entre sus muchos usos."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph11"
                                        defaultMessage="País se refiere a: España"
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph12"
                                        defaultMessage="Dispositivo significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph13"
                                        defaultMessage="Los datos personales son cualquier información que se relacione con una persona identificada o identificable."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph14"
                                        defaultMessage="El servicio se refiere al sitio web."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph15"
                                        defaultMessage="Proveedor de servicios significa cualquier persona física o jurídica que procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por la Compañía para facilitar el Servicio, para proporcionar el Servicio en nombre de la Compañía, para realizar servicios relacionados con el Servicio o para ayudar a la Compañía a analizar cómo se utiliza el Servicio."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph16"
                                        defaultMessage="Los Datos de uso se refieren a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o desde la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página)."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph17"
                                        defaultMessage="Sitio web se refiere a ZecTelecom, accesible desde "
                                    />
                                    <Link to={'/'}>https://www.zectelecom.com</Link>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph18"
                                        defaultMessage="Usted se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda."
                                    />
                                </p>
                            </li>
                        </ul>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title5"
                                defaultMessage="Recopilación y uso de sus datos personales"
                            />
                        </h1>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title6"
                                defaultMessage="Tipos de datos recopilados"
                            />
                        </h2>
                        <h3 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title7"
                                defaultMessage="Información personal"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph19"
                                defaultMessage="Al usar Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que pueda usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title8"
                                        defaultMessage="Dirección de correo electrónico"
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title9"
                                        defaultMessage="Datos de uso"
                                    />
                                </p>
                            </li>
                        </ul>
                        <h3 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title10"
                                defaultMessage="Datos de uso"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph20"
                                defaultMessage="Los datos de uso se recopilan automáticamente cuando se utiliza el servicio."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph21"
                                defaultMessage="Los datos de uso pueden incluir información como la dirección del Protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y fecha de su visita, el tiempo que pasó en esas páginas, el dispositivo único identificadores y otros datos de diagnóstico."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph22"
                                defaultMessage="Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su dispositivo móvil sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivo únicos y otros datos de diagnóstico."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph23"
                                defaultMessage="También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil."
                            />
                        </p>
                        <h3 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title11"
                                defaultMessage="Tecnologías de seguimiento y cookies"
                            />
                        </h3>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph24"
                                defaultMessage="Usamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro servicio y almacenar cierta información. Las tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar Nuestro Servicio. Las tecnologías que utilizamos pueden incluir:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_paragraph25"
                                            defaultMessage="Cookies o cookies del navegador"
                                        />
                                    </b>
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph26"
                                        defaultMessage="Una cookie es un pequeño archivo que se coloca en su dispositivo. Puede indicarle a su navegador que rechace todas las cookies o que indique cuándo se está enviando una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. A menos que haya ajustado la configuración de su navegador para que rechace las cookies, nuestro servicio puede utilizar cookies."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_paragraph27"
                                            defaultMessage="Cookies Flash: "
                                        />
                                    </b>
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph28"
                                        defaultMessage="Ciertas funciones de nuestro Servicio pueden usar objetos almacenados localmente (o Cookies Flash) para recopilar y almacenar información sobre Sus preferencias o Su actividad en nuestro Servicio. Las cookies flash no se administran con la misma configuración del navegador que las que se utilizan para las cookies del navegador."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_paragraph29"
                                            defaultMessage="Balizas web: "
                                        />
                                    </b>
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph30"
                                        defaultMessage="Ciertas secciones de nuestro Servicio y nuestros correos electrónicos pueden contener pequeños archivos electrónicos conocidos como balizas web (también conocidos como gifs transparentes, etiquetas de píxeles y gifs de un solo píxel) que permiten a la Compañía, por ejemplo, contar los usuarios que han visitado esas páginas. o abrió un correo electrónico y para otras estadísticas del sitio web relacionadas (por ejemplo, registrar la popularidad de una determinada sección y verificar la integridad del sistema y del servidor)."
                                    />
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph31"
                                defaultMessage="Para obtener más información sobre las cookies que utilizamos y sus opciones con respecto a las cookies, visite "
                            />
                            <Link to={'/cookies-policy'}>
                                <FormattedMessage
                                    id="app.hero.privacy_paragraph32"
                                    defaultMessage="nuestra Política de cookies"
                                />
                            </Link>
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title12"
                                defaultMessage="Uso de sus datos personales"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph33"
                                defaultMessage="La Compañía puede usar Datos Personales para los siguientes propósitos:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph34"
                                        defaultMessage="Para proporcionar y mantener nuestro Servicio, incluso para controlar el uso de nuestro Servicio."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph36"
                                        defaultMessage="Para administrar Su Cuenta: para administrar Su registro como usuario del Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para Usted como usuario registrado."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph37"
                                        defaultMessage="Para la ejecución de un contrato: el desarrollo, cumplimiento y ejecución del contrato de compra de los productos, artículos o servicios que haya comprado o de cualquier otro contrato con Nosotros a través del Servicio."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph38"
                                        defaultMessage="Para contactarlo: Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como las notificaciones push de una aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph39"
                                        defaultMessage="Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos y que son similares a los que ya ha comprado o sobre los que ha consultado, a menos que haya optado por no recibir dicha información."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph40"
                                        defaultMessage="Para gestionar sus solicitudes: Para atender y gestionar sus solicitudes para nosotros."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph41"
                                        defaultMessage="Para transferencias comerciales: podemos usar su información para evaluar o llevar a cabo una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como una empresa en funcionamiento o como parte de una quiebra, liquidación, o procedimiento similar, en el que los Datos personales que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph42"
                                        defaultMessage="Para otros fines: podemos utilizar su información para otros fines, como análisis de datos, identificación de tendencias de uso, determinación de la eficacia de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia."
                                    />
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title13"
                                defaultMessage="Podemos compartir su información personal en las siguientes situaciones:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph43"
                                        defaultMessage="Con proveedores de servicios: podemos compartir su información personal con proveedores de servicios para monitorear y analizar el uso de nuestro servicio, para comunicarnos con usted."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph44"
                                        defaultMessage="Para transferencias comerciales: podemos compartir o transferir Su información personal en relación con, o durante las negociaciones e, cualquier fusión, venta de activos de la Compañía, financiamiento o adquisición de la totalidad o una parte de Nuestro negocio a otra compañía."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph45"
                                        defaultMessage="Con afiliados: podemos compartir su información con nuestros afiliados, en cuyo caso les exigiremos que cumplan con esta Política de privacidad. Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlemos o que estén bajo control común con nosotros."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph46"
                                        defaultMessage="Con socios comerciales: podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph47"
                                        defaultMessage="Con otros usuarios: cuando comparte información personal o interactúa de otra manera en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente al exterior."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_paragraph48"
                                        defaultMessage="Con su consentimiento: podemos divulgar su información personal para cualquier otro propósito con su consentimiento."
                                    />
                                </p>
                            </li>
                        </ul>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title14"
                                defaultMessage="Conservación de sus datos personales"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph49"
                                defaultMessage="La Compañía retendrá sus datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta política de privacidad. Retendremos y usaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph50"
                                defaultMessage="La Compañía también retendrá los Datos de uso con fines de análisis interno. Los datos de uso generalmente se conservan por un período de tiempo más corto, excepto cuando estos datos se usan para fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o estamos legalmente obligados a retener estos datos por períodos de tiempo más largos."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title15"
                                defaultMessage="Transferencia de sus datos personales"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph51"
                                defaultMessage="Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph52"
                                defaultMessage="Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su aceptación de esa transferencia."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph53"
                                defaultMessage="La Compañía tomará todas las medidas razonablemente necesarias para garantizar que Sus datos sean tratados de forma segura y de acuerdo con esta Política de Privacidad y no se realizará ninguna transferencia de Sus Datos Personales a una organización o país a menos que existan controles adecuados establecidos, incluida la seguridad de Tus datos y otra información personal."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title16"
                                defaultMessage="Divulgación de sus datos personales"
                            />
                        </h1>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title17"
                                defaultMessage="Transacciones de negocios"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph54"
                                defaultMessage="Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus Datos personales pueden ser transferidos. Le avisaremos antes de que sus datos personales se transfieran y estén sujetos a una política de privacidad diferente."
                            />
                        </p>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title18"
                                defaultMessage="Cumplimiento de la ley"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph55"
                                defaultMessage="En determinadas circunstancias, es posible que se le solicite a la Compañía que revele sus datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental)."
                            />
                        </p>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title19"
                                defaultMessage="Otros requisitos legales"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph56"
                                defaultMessage="La Compañía puede divulgar sus datos personales si cree de buena fe que dicha acción es necesaria para:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title20"
                                        defaultMessage="Cumplir con una obligación legal"
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title21"
                                        defaultMessage="Proteger y defender los derechos o propiedad de la Compañía."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title22"
                                        defaultMessage="Prevenir o investigar posibles irregularidades en relación con el Servicio."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title23"
                                        defaultMessage="Proteger la seguridad personal de los Usuarios del Servicio o del público."
                                    />
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <FormattedMessage
                                        id="app.hero.privacy_title24"
                                        defaultMessage="Protéjase contra la responsabilidad legal"
                                    />
                                </p>
                            </li>
                        </ul>
                        <h2 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title25"
                                defaultMessage="Seguridad de sus datos personales"
                            />
                        </h2>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph57"
                                defaultMessage="La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title26"
                                defaultMessage="Privacidad de los niños"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph58"
                                defaultMessage="Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 13 años. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado datos personales, por favor Contáctenos. Si nos damos cuenta de que hemos recopilado Datos personales de cualquier persona menor de 13 años sin la verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de Nuestros servidores."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph59"
                                defaultMessage="Si necesitamos depender del consentimiento como base legal para procesar su información y su país requiere el consentimiento de uno de los padres, es posible que necesitemos el consentimiento de sus padres antes de recopilar y utilizar esa información."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title27"
                                defaultMessage="Enlaces a otros sitios web"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph60"
                                defaultMessage="Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic en el enlace de un tercero, se lo dirigirá al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph61"
                                defaultMessage="No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_title28"
                                defaultMessage="Cambios a esta política de privacidad"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph62"
                                defaultMessage="Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando la nueva Política de privacidad en esta página."
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph63"
                                defaultMessage='Le informaremos por correo electrónico y / o un aviso destacado en Nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de privacidad.'
                            />
                        </p>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph64"
                                defaultMessage="Se le aconseja que revise esta Política de privacidad periódicamente para ver si hay cambios. Los cambios a esta Política de privacidad entran en vigencia cuando se publican en esta página."
                            />
                        </p>
                        <h1 dir="ltr">
                            <FormattedMessage
                                id="app.hero.contact"
                                defaultMessage="Contáctenos"
                            />
                        </h1>
                        <p dir="ltr">
                            <FormattedMessage
                                id="app.hero.privacy_paragraph65"
                                defaultMessage="Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:"
                            />
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_title30"
                                            defaultMessage="Por correo electrónico: "
                                        />
                                    </b>info@zectelecom.com
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_title31"
                                            defaultMessage="Visitando esta página en nuestro sitio web: "
                                        />
                                    </b>
                                    <Link to={'/'}>https://www.zectelecom.com</Link>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_title32"
                                            defaultMessage="Por número de teléfono: "
                                        />
                                    </b>900879345
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <b>
                                        <FormattedMessage
                                            id="app.hero.privacy_title33"
                                            defaultMessage="Por correo: "
                                        />
                                    </b>Avenida de la onu, 23, 28936, Madrid
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

ContentPrivacy.propTypes = propTypes;
ContentPrivacy.defaultProps = defaultProps;

export default ContentPrivacy;