import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Image from '../elements/Image';
import ContentCookie from "./partials/ContentCookie";
import Cta_About from "./Cta_about";
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero_Cookies = ({
                          className,
                          topOuterDivider,
                          bottomOuterDivider,
                          topDivider,
                          bottomDivider,
                          hasBgColor,
                          invertColor,
                          ...props
                      }) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            <span className="text-color-primary">
                                <FormattedMessage
                                    id="app.hero.cookies_title"
                                    defaultMessage="Política de cookies"
                                />
                            </span>
                        </h1>
                        <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px"
                             data-reveal-delay="800">
                            <Image
                                src={require('./../../assets/images/cookiesPolicy.png')}
                                alt="Hero"
                                width={500}
                                height={32}/>
                        </div>
                        <ContentCookie/>
                        <Cta_About split/>
                    </div>
                </div>
            </div>
        </section>
    );
}

Hero_Cookies.propTypes = propTypes;
Hero_Cookies.defaultProps = defaultProps;

export default Hero_Cookies;