import React from 'react';
// import sections
import Hero_Privacy from "../components/sections/Hero_privacy";

const PrivacyPolicy = () => {

    return (
        <>
            <Hero_Privacy className="illustration-section-01" />
        </>
    );
}

export default PrivacyPolicy;