import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {FormattedMessage} from "react-intl";

const FooterNav = ({
                       className,
                       ...props
                   }) => {

    const classes = classNames(
        'footer-nav',
        className
    );

    return (
        <nav{...props} className={classes}>
            <ul className="list-reset">
                <li>
                    <Link to="/contact">
                        <FormattedMessage
                            id="app.hero.contactUs"
                            defaultMessage="contactUs"
                        />
                    </Link>
                </li>
                <li>
                    <Link to="/about">
                        <FormattedMessage
                            id="app.hero.aboutUs"
                            defaultMessage="Sobre nosotros"
                        />
                    </Link>
                </li>
                <li>
                    <Link to="/privacy-policy">
                        <FormattedMessage
                            id="app.hero.privacy"
                            defaultMessage="Política de privacidad"
                        />
                    </Link>
                </li>
                <li>
                    <Link to="/cookies-policy">
                        <FormattedMessage
                            id="app.hero.cookies"
                            defaultMessage="Política de cookies"
                        />
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default FooterNav;