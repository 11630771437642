import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Button from "../elements/Button";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}

const Cta_About = ({
                       className,
                       topOuterDivider,
                       bottomOuterDivider,
                       topDivider,
                       bottomDivider,
                       hasBgColor,
                       invertColor,
                       split,
                       ...props
                   }) => {

    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'cta-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <div className="cta-slogan">
                        <h3 className="m-0">
                            <FormattedMessage
                                id="app.hero.cta"
                                defaultMessage="Siéntete libre de contactarnos"
                            />
                        </h3>
                    </div>
                    <div className="cta-action">
                        <Link to="/contact">
                            <Button wideMobile color="primary">
                                <FormattedMessage
                                    id="app.hero.cta_button"
                                    defaultMessage="Ir a contacto"
                                />
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

Cta_About.propTypes = propTypes;
Cta_About.defaultProps = defaultProps;

export default Cta_About;