import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Image from '../elements/Image';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero_About = ({
                        className,
                        topOuterDivider,
                        bottomOuterDivider,
                        topDivider,
                        bottomDivider,
                        hasBgColor,
                        invertColor,
                        ...props
                    }) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section{...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            <span className="text-color-primary">
                                <FormattedMessage
                                    id="app.hero.aboutUs_title"
                                    defaultMessage="Conócenos"
                                />
                            </span>
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                                <FormattedMessage
                                    id="app.hero.aboutUs_description"
                                    defaultMessage="ZecTelecom es una empresa especializada en diseño y desarrollo web fundada en 2021 con la finalidad de proveer un servicio rápido, eficaz y de garantía a cualquier persona o negocio que lo necesite."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px"
                         data-reveal-delay="800">
                        <Image
                            src={require('./../../assets/images/urban-853.png')}
                            alt="Hero"
                            width={768}
                            height={540}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

Hero_About.propTypes = propTypes;
Hero_About.defaultProps = defaultProps;

export default Hero_About;