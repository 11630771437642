import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FeaturesTiles_About = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           pushLeft,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0 mt-5',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Nuestro equipo',
        paragraph: 'Estas son las personas que conforman ZecTelecom',
        section: 'featureTiles_about'
    };

    return (
        <section{...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={tilesClasses}>
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/ivan.png')}
                                            alt="Features tile icon 01"
                                            width={128}
                                            height={128}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        
                                    </h4>
                                    <p className="m-0 text-sm text-color-primary">
                                        <FormattedMessage
                                            id="app.hero.feature_tiles_AboutUs_ivan"
                                            defaultMessage="Desarrollador web full-stack"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/david.png')}
                                            alt="Features tile icon 02"
                                            width={141}
                                            height={128}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        
                                    </h4>
                                    <p className="m-0 text-sm text-color-primary">
                                        <FormattedMessage
                                            id="app.hero.feature_tiles_AboutUs_david"
                                            defaultMessage="Desarrollador web backend"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/mario.png')}
                                            alt="Features tile icon 03"
                                            width={122}
                                            height={128}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        
                                    </h4>
                                    <p className="m-0 text-sm text-color-primary">
                                        <FormattedMessage
                                            id="app.hero.feature_tiles_AboutUs_mario"
                                            defaultMessage="Desarrollador web full-stack"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesTiles_About.propTypes = propTypes;
FeaturesTiles_About.defaultProps = defaultProps;

export default FeaturesTiles_About;