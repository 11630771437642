import React from 'react';
// import sections
import Hero_Cookies from "../components/sections/Hero_cookies";

const CookiesPolicy = () => {

    return (
        <>
            <Hero_Cookies className="illustration-section-01" />
        </>
    );
}

export default CookiesPolicy;