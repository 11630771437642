import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from "react-intl";

const propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        paragraph: PropTypes.string
    }).isRequired,
    children: PropTypes.node,
    tag: PropTypes.oneOf(['h1', 'h2', 'h3'])
}

const defaultProps = {
    children: null,
    tag: 'h2'
}

const SectionHeader = ({
                           className,
                           data,
                           children,
                           tag,
                           ...props
                       }) => {

    const classes = classNames(
        'section-header',
        className
    );

    const Component = tag;

    return (
        <>
            {(data.title || data.paragraph) &&
            <div{...props} className={classes}>
                <div className="container-xs">
                    {children}
                    {
                        data.title &&
                            <Component className={
                                classNames(
                                    'mt-0',
                                    data.paragraph ? 'mb-16' : 'mb-0'
                                )}>
                                {
                                    data.section === 'featureTiles' ?
                                        <FormattedMessage
                                            id="app.hero.feature_title"
                                            defaultMessage={data.title}
                                        /> : data.section === 'featureSplit' ?
                                                <FormattedMessage
                                                    id="app.hero.feature_split_title"
                                                    defaultMessage={data.title}
                                                /> : data.section === 'featureSplit_about' ?
                                                        <FormattedMessage
                                                            id="app.hero.feature_split_AboutUs_sectionHeader_title"
                                                            defaultMessage={data.title}
                                                        /> : data.section === 'featureTiles_about' ?
                                                                <FormattedMessage
                                                                    id="app.hero.feature_tiles_AboutUs_sectionHeader_title"
                                                                    defaultMessage={data.title}
                                                                /> : <FormattedMessage
                                                                        id="app.hero.testimonials_title"
                                                                        defaultMessage={data.title}
                                                                    />
                                }
                            </Component>
                    }
                    {
                        data.paragraph &&
                            <p className="m-0">

                                {
                                    data.section === 'featureTiles' ?
                                        <FormattedMessage
                                            id="app.hero.feature_description"
                                            defaultMessage={data.description}
                                        /> : data.section === 'featureSplit' ?
                                            <FormattedMessage
                                                id="app.hero.feature_split_description"
                                                defaultMessage={data.description}
                                            /> :  data.section === 'featureSplit_about' ?
                                                    <FormattedMessage
                                                        id="app.hero.feature_split_AboutUs_sectionHeader_paragraph"
                                                        defaultMessage={data.description}
                                                    /> : data.section === 'featureTiles_about' ?
                                                            <FormattedMessage
                                                                id="app.hero.feature_tiles_AboutUs_sectionHeader_paragraph"
                                                                defaultMessage={data.description}
                                                            /> : <FormattedMessage
                                                                    id="app.hero.testimonials_description"
                                                                    defaultMessage={data.description}
                                                                />
                                }
                            </p>
                    }
                </div>
            </div>
            }
        </>
    );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;