import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {FormattedMessage} from "react-intl";

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit_About = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           invertMobile,
                           invertDesktop,
                           alignTop,
                           imageFill,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: '¿Cómo trabajamos?',
        paragraph: 'Nuestro propósito es ayudar a afrontar y gestionar de manera efectiva los retos de la actual competencia empresarial, partiendo de un profundo conocimiento de los negocios y sectores en los que operan nuestros clientes.',
        section: 'featureSplit_about'
    };

    return (
        <section{...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={splitClasses}>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left"
                                 data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12 text-color-primary">
                                    <FormattedMessage
                                        id="app.hero.feature_split_AboutUs_title_one"
                                        defaultMessage="Nuestro enfoque"
                                    />
                                </h3>
                                <p className="m-0">
                                    <FormattedMessage
                                        id="app.hero.feature_split_AboutUs_description_one"
                                        defaultMessage="Nuestro enfoque consiste en la consultoría 1-1 y actuar como una extensión de su empresa. Nos integramos totalmente y actuamos como su equipo y le proporcionamos soluciones rápidas y prácticas."
                                    />
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                 data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/teams-women-discussing-graphs-in-teamwork.png')}
                                    alt="Features split 01"
                                    width={528}
                                    height={396}/>
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-right"
                                 data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12 text-color-primary">
                                    <FormattedMessage
                                        id="app.hero.feature_split_AboutUs_title_two"
                                        defaultMessage="Nuestra misión"
                                    />
                                </h3>
                                <p className="m-0">
                                    <FormattedMessage
                                        id="app.hero.feature_split_AboutUs_description_two"
                                        defaultMessage="Nuestra misión es proporcionar la mejor consultoría de marketing digital, servicios e implementar la tecnología líder en la industria."
                                    />
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                 data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/teams-planning-and-discussion-of-new-ideas-by-men.png')}
                                    alt="Features split 02"
                                    width={528}
                                    height={396}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesSplit_About.propTypes = propTypes;
FeaturesSplit_About.defaultProps = defaultProps;

export default FeaturesSplit_About;